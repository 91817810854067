// extracted by mini-css-extract-plugin
export var button = "sign-in-page-module--button--Ppjpo";
export var ccsDiv = "sign-in-page-module--ccsDiv--ooruw";
export var contactDetailsIcon = "sign-in-page-module--contactDetailsIcon--ijcCg";
export var contactFormDiv = "sign-in-page-module--contactFormDiv--OcoQM";
export var contactFormInputsDiv = "sign-in-page-module--contactFormInputsDiv--DNfpU";
export var displayDiv = "sign-in-page-module--displayDiv--fdtrn";
export var emailDiv = "sign-in-page-module--emailDiv--hfCBB";
export var footerContentDiv = "sign-in-page-module--footerContentDiv--shkDI";
export var footerDiv = "sign-in-page-module--footerDiv--wcjW4";
export var footerLDiv = "sign-in-page-module--footerLDiv--XmfNZ";
export var footerLinksDiv = "sign-in-page-module--footerLinksDiv--AcwVd";
export var footerLogoDiv = "sign-in-page-module--footerLogoDiv--MJvip";
export var footerRDiv = "sign-in-page-module--footerRDiv--D1UmL";
export var formTitleDiv = "sign-in-page-module--formTitleDiv--RpjCD";
export var globalCareSupply = "sign-in-page-module--globalCareSupply--urgeC";
export var ifYouAreNotRegisteredGo = "sign-in-page-module--ifYouAreNotRegisteredGo--z+kMk";
export var input = "sign-in-page-module--input--z1WeH";
export var inputFieldWIthLabelDiv = "sign-in-page-module--inputFieldWIthLabelDiv--3W+wI";
export var inputLabelDiv = "sign-in-page-module--inputLabelDiv--pd3iO";
export var inputLabelDiv2 = "sign-in-page-module--inputLabelDiv2--b5fWP";
export var inputRowDiv = "sign-in-page-module--inputRowDiv--OvUSa";
export var logoA = "sign-in-page-module--logoA--ub7fx";
export var privacyPolicy = "sign-in-page-module--privacyPolicy--kI807";
export var registerContentDiv = "sign-in-page-module--registerContentDiv--sS39Q";
export var registerPageB = "sign-in-page-module--registerPageB--GQoQj";
export var registerSectionDiv = "sign-in-page-module--registerSectionDiv--3rs+0";
export var signInPageDiv = "sign-in-page-module--signInPageDiv--vIMyL";
export var udshSymbol1Icon = "sign-in-page-module--udshSymbol1Icon--S3Isf";
export var visaIcon = "sign-in-page-module--visaIcon--6idCw";